<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm" title="LBLDETAIL">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="editable&&!disabled&&isOld" 
            label="LBLREMOVE" 
            icon="delete" 
            @btnClicked="deleteSurvey" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="survey"
            :mappingType="mappingType"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveSurvey"
            @btnCallback="saveSurveyCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-field
            :required="true"
            :disabled="disabled||isOld"
            :editable="editable"
            :data="survey"
            deptValue="deptCd"
            type="dept_user"
            label="근로자"
            name="userId"
            v-model="survey.userId" 
            @dataChange="userChange"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-text
            :disabled="true"
            label="성별 / 만나이 (검진일 기점)"
            name="sexAge"
            v-model="sexAge"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-datepicker
            :required="true"
            :disabled="disabled"
            :editable="editable"
            default="today"
            label="검진일"
            name="surveyDate"
            v-model="survey.surveyDate"
            @datachange="calculationAge"
          />
        </div>
      </template>
    </c-card>
    <c-table
      class="q-mt-sm brain-cardiovascular-survey q-pb-md"
      title="증사조사표"
      :columns="grid.columns"
      :data="gridData"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      isDense
      :isTitle="false"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00005')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000001')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000002')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000003')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000004')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000008')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000009')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===2" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000011')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000013')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000031')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000032')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000014')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000015')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===3" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000033')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000034')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000035')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000069')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===4" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00015')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===5" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000006')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000007')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000036')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000037')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
        </q-tr>

        <q-tr :props="props" v-if="props.rowIndex===6" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00001')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===7" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000040')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000041')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000042')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000043')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000044')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000045')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===8" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000070')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===9" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00010')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===10" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000046')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000047')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000048')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000049')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000050')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000051')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===11" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000052')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000053')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000055')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000056')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000057')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000058')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===12" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000054')"
            :editable="editable&&!disabled"
            :colspan="12"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===13" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00020')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===14" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000059')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000060')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000061')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000062')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===15" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00025')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===16" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000063')"
            :editable="editable&&!disabled"
            :colspan="2"
            trueLabel="O"
            falseLabel="X"
            type="check"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000064')"
            :editable="editable&&!disabled"
            :colspan="2"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000065')"
            :editable="editable&&!disabled"
            :colspan="8"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===17" no-hover>
          <q-td
            class="text-center th-td-style th-style"
            :rowspan="1" :colspan="12">
            <span>
              {{evalItemClass('WSEIC00030')}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===18" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000066')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000067')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===19" no-hover>
          <component
            :is="evalItemTd"
            :result="getEvalItem('BCWI000068')"
            :editable="editable&&!disabled"
            :colspan="6"
            type="text"
          />
        </q-tr>
      </template>
    </c-table>
  </q-form>
</template>

<script>
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'brainCardiovascularInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',
      }),
    },
    survey: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 종합조사표 일련번호
        heaBrainCardiovascularStepCd: '',  // 진행상태
        userId: '',  // 근로자ID
        userName: '',  // 근로자명
        deptCd: '',  // 부서코드
        deptName: '',  // 부서명
        age: 0,  // 나이
        sexCd: '',  // 성별
        sexName: '',  // 성별
        birthDate: '', // 생년월일
        surveyDate: '',  // 평가일
        waistCircumference: '',  // 위험도_허리둘레
        bmi: '',  // 위험도_체질량 지수
        bloodPressure: '',  // 위험도_혈압
        hypertensionMedication: '',  // 위험도_고혈압 약 복용
        fastingBloodSugar: '',  // 위험도_공복 혈당
        diabetesMedication: '',  // 위험도_당뇨병 약 복용
        totalCholesterol: '',  // 위험도_총콜레스테롤
        egfr: '',  // 위험도_eGFR
        dipstickProteinuria: '',  // 위험도_Dipstick proteinuria 단백뇨
        smoking: '',  // 위험도_흡연상태
        physicallyActiveStatus: '',  // 위험도_신체 활동 여부
        regUserId: '',  //
        chgUserId: '',  //
        results: [], // 유해요인 평가결과 목록
        riskAssessment: {
          heaBrainCardiovascularRiskAssessmentId: '',  // 뇌심혈관계 위험평가 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          adjustedCompositeRelativeRisk: '',  // 조정 복합 상대위험도
          riskDevelopDisease: '',  // 발병위험도(10년)
          avgRiskDevelopDisease: '',  // 평균발병위험도(10년)
          riskOrthodonticDevelopment: '',  // 교정발병위험도(10년)
          reducedRiskDevelopDisease: '',  // 발병위험도 감소치
          cardiovascularAge: '',  // 심뇌혈관 나이
          correctedCardiovascularAge: '',  // 교정 심뇌혈관 나이
          integratedRiskClassificationCriteria: '',  // 통합형 위험도 분류기준
          /** 기타 정보 */
          complexRelativeRisk: '',  // 개인별 복합 상대위험도
          correctionCompositeRelativeRisk: '',  // 개인별 교정 복합 상대위험도
          regUserId: '',  //
          chgUserId: '',  //
        }, // 위험평가
        suitEval: {
          heaBrainCardiovascularWorkSuitEvalId: '',  // 뇌심혈관계 업무적합성 일련번호
          heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
          userId: '',  // 근로자ID (종합조사표 값)
          userName: '',  // 근로자명 (종합조사표 값)
          deptCd: '',  // 부서코드 (종합조사표 값)
          deptName: '',  // 부서명 (종합조사표 값)
          tasksPerformed: '',  // 수행업무
          diseaseReview: '',  // 임상증상 / 동반질환 검토의견
          habitsReview: '',  // 생활습관 / 검사항목 검토의견
          businessFactorsReview: '',  // 업무적요인 검토의견
          generalOpinionCd: '',  // 종합의견 - 결과
          generalOpinion: '',  // 종합의견 - 적합 이외인 경우 기술
          heaManagerId: '',  // 보건담당자 OR 의사
          regUserId: '',  //
          chgUserId: '',  //
        }, // 업무적합성 평가서
      }),
    },
    evalItemClasses: {
      type: Array,
      default: () => [],
    },
    isOld: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          { name: '1', field: '1', style: 'width:100px' },
          { name: '2', field: '2', style: 'width:150px' },
          { name: '3', field: '3', style: 'width:100px' },
          { name: '4', field: '4', style: 'width:150px' },
          { name: '5', field: '5', style: 'width:100px' },
          { name: '6', field: '6', style: 'width:150px' },
          { name: '7', field: '7', style: 'width:100px' },
          { name: '8', field: '8', style: 'width:150px' },
          { name: '9', field: '9', style: 'width:150px' },
          { name: '10', field: '10', style: 'width:150px' },
          { name: '11', field: '11', style: 'width:150px' },
          { name: '12', field: '12', style: 'width:150px' },
        ],
        data: [],
      },
      editable: true,
      isSave: true,
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.isOld && this.survey.heaBrainCardiovascularStepCd !== 'HBCS000001'
    },
    sexAge() {
      return `${this.survey.age} / ${this.survey.sexName}`
    },
    gridData() {
      let data = [];
      for (let idx = 0; idx < 20; idx++) {
        data.push({})
      }
      return data;
    },
    evalItemTd() {
      return () => import(`${'@/pages/hea/bcs/brainCardiovascularInfoItemTd.vue'}`)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.hea.bcs.survey.insert.url
      this.deleteUrl = transactionConfig.hea.bcs.survey.delete.url
      // code setting
      // list setting
    },
    userChange(userId, user) {
      this.$set(this.survey, 'userName', user.userName)
      this.$set(this.survey, 'deptCd', user.deptCd)
      this.$set(this.survey, 'deptName', user.deptName)
      this.$set(this.survey, 'sexCd', user.sexCd)
      this.$set(this.survey, 'sexName', user.sexName)
      this.$set(this.survey, 'birthDate', user.birthDate)

      this.calculationAge();
    },
    calculationAge() {
      if (this.survey.surveyDate && this.survey.birthDate) {
        let age = 0;
        // 생일
        let birthDate = new Date(this.survey.birthDate)
        let birthYear = birthDate.getFullYear();
        let birthMonth = birthDate.getMonth();
        let birthDay = birthDate.getDate();
        // 기준일
        let standardData = new Date(this.survey.surveyDate)
        let standardYear = standardData.getFullYear();
        let standardMonth = standardData.getMonth();
        let standardDay = standardData.getDate();

        age = standardYear - birthYear;
        if (standardMonth < birthMonth || (standardMonth === birthMonth && birthDay < standardDay)) {
          age--;
        }
        this.$set(this.survey, 'age', age)
      } else {
        this.$set(this.survey, 'age', 0)
      }
    },
    evalItemClass(workSuitEvalItemClassCd) {
      let text = '';
      if (this.evalItemClasses && this.evalItemClasses.length > 0) {
        text = this.$_.find(this.evalItemClasses, { code: workSuitEvalItemClassCd }).codeName
      }
      return text;
    },
    getEvalItem(heaBrainCardiovascularEvalItemId) {
      return this.$_.find(this.survey.results, { heaBrainCardiovascularEvalItemId: heaBrainCardiovascularEvalItemId })
    },
    saveSurvey() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.hea.bcs.survey.update.url
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.hea.bcs.survey.insert.url
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.survey.regUserId = this.$store.getters.user.userId
              this.survey.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSurveyCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'heaBrainCardiovascularSurveyId', _result.data)
      this.$emit('getDetail')
    },
    deleteSurvey() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.heaBrainCardiovascularSurveyId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
<style lang="sass">
.brain-cardiovascular-survey
  .th-td-style
    vertical-align: middle !important
  .th-style
    background: #EEEEEE !important
    font-weight: 700
    width: 100%
  .td-style
    width: 16.6%
  .etc-info
    display: inline-block
    float: right
</style>